export const PLAYER_TO_TEAM = {'Jalen Johnson': {'team': 'Atlanta Hawks', 'team_abbr': 'ATL'},
'Trent Forrest': {'team': 'Atlanta Hawks', 'team_abbr': 'ATL'},
'Seth Lundy': {'team': 'Atlanta Hawks', 'team_abbr': 'ATL'},
'Kobe Bufkin': {'team': 'Atlanta Hawks', 'team_abbr': 'ATL'},
'Dejounte Murray': {'team': 'Atlanta Hawks', 'team_abbr': 'ATL'},
'Patty Mills': {'team': 'Atlanta Hawks', 'team_abbr': 'ATL'},
'Trae Young': {'team': 'Atlanta Hawks', 'team_abbr': 'ATL'},
"De'Andre Hunter": {'team': 'Atlanta Hawks', 'team_abbr': 'ATL'},
'Bogdan Bogdanovic': {'team': 'Atlanta Hawks', 'team_abbr': 'ATL'},
'AJ Griffin': {'team': 'Atlanta Hawks', 'team_abbr': 'ATL'},
'Clint Capela': {'team': 'Atlanta Hawks', 'team_abbr': 'ATL'},
'Onyeka Okongwu': {'team': 'Atlanta Hawks', 'team_abbr': 'ATL'},
'Mouhamed Gueye': {'team': 'Atlanta Hawks', 'team_abbr': 'ATL'},
'Bruno Fernando': {'team': 'Atlanta Hawks', 'team_abbr': 'ATL'},
'Garrison Mathews': {'team': 'Atlanta Hawks', 'team_abbr': 'ATL'},
'Vit Krejci': {'team': 'Atlanta Hawks', 'team_abbr': 'ATL'},
'Wesley Matthews': {'team': 'Atlanta Hawks', 'team_abbr': 'ATL'},
'Saddiq Bey': {'team': 'Atlanta Hawks', 'team_abbr': 'ATL'},
'Drew Peterson': {'team': 'Boston Celtics', 'team_abbr': 'BOS'},
'Jayson Tatum': {'team': 'Boston Celtics', 'team_abbr': 'BOS'},
'Jrue Holiday': {'team': 'Boston Celtics', 'team_abbr': 'BOS'},
'Jaylen Brown': {'team': 'Boston Celtics', 'team_abbr': 'BOS'},
'Kristaps Porzingis': {'team': 'Boston Celtics', 'team_abbr': 'BOS'},
'Derrick White': {'team': 'Boston Celtics', 'team_abbr': 'BOS'},
'Payton Pritchard': {'team': 'Boston Celtics', 'team_abbr': 'BOS'},
'Oshae Brissett': {'team': 'Boston Celtics', 'team_abbr': 'BOS'},
'JD Davison': {'team': 'Boston Celtics', 'team_abbr': 'BOS'},
'Jordan Walsh': {'team': 'Boston Celtics', 'team_abbr': 'BOS'},
'Sam Hauser': {'team': 'Boston Celtics', 'team_abbr': 'BOS'},
'Luke Kornet': {'team': 'Boston Celtics', 'team_abbr': 'BOS'},
'Al Horford': {'team': 'Boston Celtics', 'team_abbr': 'BOS'},
'Dalano Banton': {'team': 'Boston Celtics', 'team_abbr': 'BOS'},
'Svi Mykhailiuk': {'team': 'Boston Celtics', 'team_abbr': 'BOS'},
'Lamar Stevens': {'team': 'Boston Celtics', 'team_abbr': 'BOS'},
'Neemias Queta': {'team': 'Boston Celtics', 'team_abbr': 'BOS'},
'Max Strus': {'team': 'Cleveland Cavaliers', 'team_abbr': 'CLE'},
'Ty Jerome': {'team': 'Cleveland Cavaliers', 'team_abbr': 'CLE'},
'Caris LeVert': {'team': 'Cleveland Cavaliers', 'team_abbr': 'CLE'},
'Evan Mobley': {'team': 'Cleveland Cavaliers', 'team_abbr': 'CLE'},
'Sam Merrill': {'team': 'Cleveland Cavaliers', 'team_abbr': 'CLE'},
'Craig Porter Jr.': {'team': 'Cleveland Cavaliers', 'team_abbr': 'CLE'},
'Darius Garland': {'team': 'Cleveland Cavaliers', 'team_abbr': 'CLE'},
'Tristan Thompson': {'team': 'Cleveland Cavaliers', 'team_abbr': 'CLE'},
'Ricky Rubio': {'team': 'Cleveland Cavaliers', 'team_abbr': 'CLE'},
'Isaiah Mobley': {'team': 'Cleveland Cavaliers', 'team_abbr': 'CLE'},
'Georges Niang': {'team': 'Cleveland Cavaliers', 'team_abbr': 'CLE'},
'Emoni Bates': {'team': 'Cleveland Cavaliers', 'team_abbr': 'CLE'},
'Damian Jones': {'team': 'Cleveland Cavaliers', 'team_abbr': 'CLE'},
'Jarrett Allen': {'team': 'Cleveland Cavaliers', 'team_abbr': 'CLE'},
'Dean Wade': {'team': 'Cleveland Cavaliers', 'team_abbr': 'CLE'},
'Isaac Okoro': {'team': 'Cleveland Cavaliers', 'team_abbr': 'CLE'},
'Donovan Mitchell': {'team': 'Cleveland Cavaliers', 'team_abbr': 'CLE'},
'Dereon Seabron': {'team': 'New Orleans Pelicans', 'team_abbr': 'NOP'},
'Zion Williamson': {'team': 'New Orleans Pelicans', 'team_abbr': 'NOP'},
'CJ McCollum': {'team': 'New Orleans Pelicans', 'team_abbr': 'NOP'},
'Herbert Jones': {'team': 'New Orleans Pelicans', 'team_abbr': 'NOP'},
'Naji Marshall': {'team': 'New Orleans Pelicans', 'team_abbr': 'NOP'},
'Dyson Daniels': {'team': 'New Orleans Pelicans', 'team_abbr': 'NOP'},
'Kira Lewis Jr.': {'team': 'New Orleans Pelicans', 'team_abbr': 'NOP'},
'Brandon Ingram': {'team': 'New Orleans Pelicans', 'team_abbr': 'NOP'},
'Jose Alvarado': {'team': 'New Orleans Pelicans', 'team_abbr': 'NOP'},
'Jonas Valanciunas': {'team': 'New Orleans Pelicans', 'team_abbr': 'NOP'},
'Larry Nance Jr.': {'team': 'New Orleans Pelicans', 'team_abbr': 'NOP'},
'Jordan Hawkins': {'team': 'New Orleans Pelicans', 'team_abbr': 'NOP'},
'Trey Murphy III': {'team': 'New Orleans Pelicans', 'team_abbr': 'NOP'},
'E.J. Liddell': {'team': 'New Orleans Pelicans', 'team_abbr': 'NOP'},
'Matt Ryan': {'team': 'New Orleans Pelicans', 'team_abbr': 'NOP'},
'Cody Zeller': {'team': 'New Orleans Pelicans', 'team_abbr': 'NOP'},
'Jeremiah Robinson-Earl': {'team': 'New Orleans Pelicans',
 'team_abbr': 'NOP'},
'Henri Drell': {'team': 'Chicago Bulls', 'team_abbr': 'CHI'},
'Coby White': {'team': 'Chicago Bulls', 'team_abbr': 'CHI'},
'Lonzo Ball': {'team': 'Chicago Bulls', 'team_abbr': 'CHI'},
'Andre Drummond': {'team': 'Chicago Bulls', 'team_abbr': 'CHI'},
'Jevon Carter': {'team': 'Chicago Bulls', 'team_abbr': 'CHI'},
'Alex Caruso': {'team': 'Chicago Bulls', 'team_abbr': 'CHI'},
'Zach LaVine': {'team': 'Chicago Bulls', 'team_abbr': 'CHI'},
'Nikola Vucevic': {'team': 'Chicago Bulls', 'team_abbr': 'CHI'},
'DeMar DeRozan': {'team': 'Chicago Bulls', 'team_abbr': 'CHI'},
'Ayo Dosunmu': {'team': 'Chicago Bulls', 'team_abbr': 'CHI'},
'Torrey Craig': {'team': 'Chicago Bulls', 'team_abbr': 'CHI'},
'Julian Phillips': {'team': 'Chicago Bulls', 'team_abbr': 'CHI'},
'Onuralp Bitim': {'team': 'Chicago Bulls', 'team_abbr': 'CHI'},
'Adama Sanogo': {'team': 'Chicago Bulls', 'team_abbr': 'CHI'},
'Dalen Terry': {'team': 'Chicago Bulls', 'team_abbr': 'CHI'},
'Terry Taylor': {'team': 'Chicago Bulls', 'team_abbr': 'CHI'},
'Patrick Williams': {'team': 'Chicago Bulls', 'team_abbr': 'CHI'},
'Dante Exum': {'team': 'Dallas Mavericks', 'team_abbr': 'DAL'},
'Jaden Hardy': {'team': 'Dallas Mavericks', 'team_abbr': 'DAL'},
'Dereck Lively II': {'team': 'Dallas Mavericks', 'team_abbr': 'DAL'},
'Grant Williams': {'team': 'Dallas Mavericks', 'team_abbr': 'DAL'},
'Dwight Powell': {'team': 'Dallas Mavericks', 'team_abbr': 'DAL'},
'Josh Green': {'team': 'Dallas Mavericks', 'team_abbr': 'DAL'},
'A.J. Lawson': {'team': 'Dallas Mavericks', 'team_abbr': 'DAL'},
'Tim Hardaway Jr.': {'team': 'Dallas Mavericks', 'team_abbr': 'DAL'},
'Kyrie Irving': {'team': 'Dallas Mavericks', 'team_abbr': 'DAL'},
'Olivier-Maxence Prosper': {'team': 'Dallas Mavericks', 'team_abbr': 'DAL'},
'Richaun Holmes': {'team': 'Dallas Mavericks', 'team_abbr': 'DAL'},
'Seth Curry': {'team': 'Dallas Mavericks', 'team_abbr': 'DAL'},
'Greg Brown III': {'team': 'Dallas Mavericks', 'team_abbr': 'DAL'},
'Dexter Dennis': {'team': 'Dallas Mavericks', 'team_abbr': 'DAL'},
'Maxi Kleber': {'team': 'Dallas Mavericks', 'team_abbr': 'DAL'},
'Derrick Jones Jr.': {'team': 'Dallas Mavericks', 'team_abbr': 'DAL'},
'Luka Doncic': {'team': 'Dallas Mavericks', 'team_abbr': 'DAL'},
'Markieff Morris': {'team': 'Dallas Mavericks', 'team_abbr': 'DAL'},
'Christian Braun': {'team': 'Denver Nuggets', 'team_abbr': 'DEN'},
'Michael Porter Jr.': {'team': 'Denver Nuggets', 'team_abbr': 'DEN'},
'Julian Strawther': {'team': 'Denver Nuggets', 'team_abbr': 'DEN'},
'Hunter Tyson': {'team': 'Denver Nuggets', 'team_abbr': 'DEN'},
'Kentavious Caldwell-Pope': {'team': 'Denver Nuggets', 'team_abbr': 'DEN'},
'DeAndre Jordan': {'team': 'Denver Nuggets', 'team_abbr': 'DEN'},
'Reggie Jackson': {'team': 'Denver Nuggets', 'team_abbr': 'DEN'},
'Peyton Watson': {'team': 'Denver Nuggets', 'team_abbr': 'DEN'},
'Justin Holiday': {'team': 'Denver Nuggets', 'team_abbr': 'DEN'},
'Braxton Key': {'team': 'Denver Nuggets', 'team_abbr': 'DEN'},
'Nikola Jokic': {'team': 'Denver Nuggets', 'team_abbr': 'DEN'},
'Collin Gillespie': {'team': 'Denver Nuggets', 'team_abbr': 'DEN'},
'Zeke Nnaji': {'team': 'Denver Nuggets', 'team_abbr': 'DEN'},
'Jalen Pickett': {'team': 'Denver Nuggets', 'team_abbr': 'DEN'},
'Jamal Murray': {'team': 'Denver Nuggets', 'team_abbr': 'DEN'},
'Jay Huff': {'team': 'Denver Nuggets', 'team_abbr': 'DEN'},
'Vlatko Cancar': {'team': 'Denver Nuggets', 'team_abbr': 'DEN'},
'Aaron Gordon': {'team': 'Denver Nuggets', 'team_abbr': 'DEN'},
'Gary Payton II': {'team': 'Golden State Warriors', 'team_abbr': 'GSW'},
'Jonathan Kuminga': {'team': 'Golden State Warriors', 'team_abbr': 'GSW'},
'Cory Joseph': {'team': 'Golden State Warriors', 'team_abbr': 'GSW'},
'Brandin Podziemski': {'team': 'Golden State Warriors', 'team_abbr': 'GSW'},
'Chris Paul': {'team': 'Golden State Warriors', 'team_abbr': 'GSW'},
'Moses Moody': {'team': 'Golden State Warriors', 'team_abbr': 'GSW'},
'Kevon Looney': {'team': 'Golden State Warriors', 'team_abbr': 'GSW'},
'Klay Thompson': {'team': 'Golden State Warriors', 'team_abbr': 'GSW'},
'Usman Garuba': {'team': 'Golden State Warriors', 'team_abbr': 'GSW'},
'Gui Santos': {'team': 'Golden State Warriors', 'team_abbr': 'GSW'},
'Jerome Robinson': {'team': 'Golden State Warriors', 'team_abbr': 'GSW'},
'Dario Saric': {'team': 'Golden State Warriors', 'team_abbr': 'GSW'},
'Andrew Wiggins': {'team': 'Golden State Warriors', 'team_abbr': 'GSW'},
'Draymond Green': {'team': 'Golden State Warriors', 'team_abbr': 'GSW'},
'Lester Quinones': {'team': 'Golden State Warriors', 'team_abbr': 'GSW'},
'Stephen Curry': {'team': 'Golden State Warriors', 'team_abbr': 'GSW'},
'Trayce Jackson-Davis': {'team': 'Golden State Warriors', 'team_abbr': 'GSW'},
'Jermaine Samuels Jr.': {'team': 'Houston Rockets', 'team_abbr': 'HOU'},
'Aaron Holiday': {'team': 'Houston Rockets', 'team_abbr': 'HOU'},
'Amen Thompson': {'team': 'Houston Rockets', 'team_abbr': 'HOU'},
'Jock Landale': {'team': 'Houston Rockets', 'team_abbr': 'HOU'},
'Victor Oladipo': {'team': 'Houston Rockets', 'team_abbr': 'HOU'},
'Jalen Green': {'team': 'Houston Rockets', 'team_abbr': 'HOU'},
'Fred VanVleet': {'team': 'Houston Rockets', 'team_abbr': 'HOU'},
'Cam Whitmore': {'team': 'Houston Rockets', 'team_abbr': 'HOU'},
"Jae'Sean Tate": {'team': 'Houston Rockets', 'team_abbr': 'HOU'},
'Dillon Brooks': {'team': 'Houston Rockets', 'team_abbr': 'HOU'},
'Jabari Smith Jr.': {'team': 'Houston Rockets', 'team_abbr': 'HOU'},
'Nate Hinton': {'team': 'Houston Rockets', 'team_abbr': 'HOU'},
'Tari Eason': {'team': 'Houston Rockets', 'team_abbr': 'HOU'},
'Nate Williams': {'team': 'Houston Rockets', 'team_abbr': 'HOU'},
'Reggie Bullock Jr.': {'team': 'Houston Rockets', 'team_abbr': 'HOU'},
'Alperen Sengun': {'team': 'Houston Rockets', 'team_abbr': 'HOU'},
'Jeff Green': {'team': 'Houston Rockets', 'team_abbr': 'HOU'},
'Boban Marjanovic': {'team': 'Houston Rockets', 'team_abbr': 'HOU'},
'Russell Westbrook': {'team': 'Los Angeles Clippers', 'team_abbr': 'LAC'},
'James Harden': {'team': 'Los Angeles Clippers', 'team_abbr': 'LAC'},
'Kawhi Leonard': {'team': 'Los Angeles Clippers', 'team_abbr': 'LAC'},
'Brandon Boston Jr.': {'team': 'Los Angeles Clippers', 'team_abbr': 'LAC'},
'Bones Hyland': {'team': 'Los Angeles Clippers', 'team_abbr': 'LAC'},
'Amir Coffey': {'team': 'Los Angeles Clippers', 'team_abbr': 'LAC'},
'Daniel Theis': {'team': 'Los Angeles Clippers', 'team_abbr': 'LAC'},
'Jordan Miller': {'team': 'Los Angeles Clippers', 'team_abbr': 'LAC'},
'Joshua Primo': {'team': 'Los Angeles Clippers', 'team_abbr': 'LAC'},
'Paul George': {'team': 'Los Angeles Clippers', 'team_abbr': 'LAC'},
'Terance Mann': {'team': 'Los Angeles Clippers', 'team_abbr': 'LAC'},
'Xavier Moon': {'team': 'Los Angeles Clippers', 'team_abbr': 'LAC'},
'P.J. Tucker': {'team': 'Los Angeles Clippers', 'team_abbr': 'LAC'},
'Kobe Brown': {'team': 'Los Angeles Clippers', 'team_abbr': 'LAC'},
'Norman Powell': {'team': 'Los Angeles Clippers', 'team_abbr': 'LAC'},
'Moussa Diabate': {'team': 'Los Angeles Clippers', 'team_abbr': 'LAC'},
'Ivica Zubac': {'team': 'Los Angeles Clippers', 'team_abbr': 'LAC'},
'Mason Plumlee': {'team': 'Los Angeles Clippers', 'team_abbr': 'LAC'},
'Jalen Hood-Schifino': {'team': 'Los Angeles Lakers', 'team_abbr': 'LAL'},
"D'Angelo Russell": {'team': 'Los Angeles Lakers', 'team_abbr': 'LAL'},
'Jarred Vanderbilt': {'team': 'Los Angeles Lakers', 'team_abbr': 'LAL'},
'Anthony Davis': {'team': 'Los Angeles Lakers', 'team_abbr': 'LAL'},
'Cam Reddish': {'team': 'Los Angeles Lakers', 'team_abbr': 'LAL'},
'Gabe Vincent': {'team': 'Los Angeles Lakers', 'team_abbr': 'LAL'},
'Max Christie': {'team': 'Los Angeles Lakers', 'team_abbr': 'LAL'},
'Jaxson Hayes': {'team': 'Los Angeles Lakers', 'team_abbr': 'LAL'},
'Taurean Prince': {'team': 'Los Angeles Lakers', 'team_abbr': 'LAL'},
'Colin Castleton': {'team': 'Los Angeles Lakers', 'team_abbr': 'LAL'},
'Austin Reaves': {'team': 'Los Angeles Lakers', 'team_abbr': 'LAL'},
'Alex Fudge': {'team': 'Los Angeles Lakers', 'team_abbr': 'LAL'},
'Maxwell Lewis': {'team': 'Los Angeles Lakers', 'team_abbr': 'LAL'},
'LeBron James': {'team': 'Los Angeles Lakers', 'team_abbr': 'LAL'},
'Rui Hachimura': {'team': 'Los Angeles Lakers', 'team_abbr': 'LAL'},
'Christian Wood': {'team': 'Los Angeles Lakers', 'team_abbr': 'LAL'},
"D'Moi Hodge": {'team': 'Los Angeles Lakers', 'team_abbr': 'LAL'},
'Josh Richardson': {'team': 'Miami Heat', 'team_abbr': 'MIA'},
'R.J. Hampton': {'team': 'Miami Heat', 'team_abbr': 'MIA'},
'Nikola Jovic': {'team': 'Miami Heat', 'team_abbr': 'MIA'},
'Kyle Lowry': {'team': 'Miami Heat', 'team_abbr': 'MIA'},
'Jamal Cain': {'team': 'Miami Heat', 'team_abbr': 'MIA'},
'Dru Smith': {'team': 'Miami Heat', 'team_abbr': 'MIA'},
'Jaime Jaquez Jr.': {'team': 'Miami Heat', 'team_abbr': 'MIA'},
'Bam Adebayo': {'team': 'Miami Heat', 'team_abbr': 'MIA'},
'Tyler Herro': {'team': 'Miami Heat', 'team_abbr': 'MIA'},
'Caleb Martin': {'team': 'Miami Heat', 'team_abbr': 'MIA'},
'Cole Swider': {'team': 'Miami Heat', 'team_abbr': 'MIA'},
'Jimmy Butler': {'team': 'Miami Heat', 'team_abbr': 'MIA'},
'Haywood Highsmith': {'team': 'Miami Heat', 'team_abbr': 'MIA'},
'Orlando Robinson': {'team': 'Miami Heat', 'team_abbr': 'MIA'},
'Thomas Bryant': {'team': 'Miami Heat', 'team_abbr': 'MIA'},
'Kevin Love': {'team': 'Miami Heat', 'team_abbr': 'MIA'},
'Duncan Robinson': {'team': 'Miami Heat', 'team_abbr': 'MIA'},
'Damian Lillard': {'team': 'Milwaukee Bucks', 'team_abbr': 'MIL'},
'MarJon Beauchamp': {'team': 'Milwaukee Bucks', 'team_abbr': 'MIL'},
'Malik Beasley': {'team': 'Milwaukee Bucks', 'team_abbr': 'MIL'},
'Chris Livingston': {'team': 'Milwaukee Bucks', 'team_abbr': 'MIL'},
'Bobby Portis': {'team': 'Milwaukee Bucks', 'team_abbr': 'MIL'},
'Brook Lopez': {'team': 'Milwaukee Bucks', 'team_abbr': 'MIL'},
'Marques Bolden': {'team': 'Milwaukee Bucks', 'team_abbr': 'MIL'},
'Cameron Payne': {'team': 'Milwaukee Bucks', 'team_abbr': 'MIL'},
'AJ Green': {'team': 'Milwaukee Bucks', 'team_abbr': 'MIL'},
'Khris Middleton': {'team': 'Milwaukee Bucks', 'team_abbr': 'MIL'},
'TyTy Washington Jr.': {'team': 'Milwaukee Bucks', 'team_abbr': 'MIL'},
'Pat Connaughton': {'team': 'Milwaukee Bucks', 'team_abbr': 'MIL'},
'Lindell Wigginton': {'team': 'Milwaukee Bucks', 'team_abbr': 'MIL'},
'Giannis Antetokounmpo': {'team': 'Milwaukee Bucks', 'team_abbr': 'MIL'},
'Robin Lopez': {'team': 'Milwaukee Bucks', 'team_abbr': 'MIL'},
'Thanasis Antetokounmpo': {'team': 'Milwaukee Bucks', 'team_abbr': 'MIL'},
'Andre Jackson Jr.': {'team': 'Milwaukee Bucks', 'team_abbr': 'MIL'},
'Jae Crowder': {'team': 'Milwaukee Bucks', 'team_abbr': 'MIL'},
'Jaylen Clark': {'team': 'Minnesota Timberwolves', 'team_abbr': 'MIN'},
'Kyle Anderson': {'team': 'Minnesota Timberwolves', 'team_abbr': 'MIN'},
'Jaden McDaniels': {'team': 'Minnesota Timberwolves', 'team_abbr': 'MIN'},
'Anthony Edwards': {'team': 'Minnesota Timberwolves', 'team_abbr': 'MIN'},
'Jordan McLaughlin': {'team': 'Minnesota Timberwolves', 'team_abbr': 'MIN'},
'Wendell Moore Jr.': {'team': 'Minnesota Timberwolves', 'team_abbr': 'MIN'},
'Josh Minott': {'team': 'Minnesota Timberwolves', 'team_abbr': 'MIN'},
'Nickeil Alexander-Walker': {'team': 'Minnesota Timberwolves',
 'team_abbr': 'MIN'},
'Mike Conley': {'team': 'Minnesota Timberwolves', 'team_abbr': 'MIN'},
'Naz Reid': {'team': 'Minnesota Timberwolves', 'team_abbr': 'MIN'},
'Daishen Nix': {'team': 'Minnesota Timberwolves', 'team_abbr': 'MIN'},
'Shake Milton': {'team': 'Minnesota Timberwolves', 'team_abbr': 'MIN'},
'Troy Brown Jr.': {'team': 'Minnesota Timberwolves', 'team_abbr': 'MIN'},
'Rudy Gobert': {'team': 'Minnesota Timberwolves', 'team_abbr': 'MIN'},
'Karl-Anthony Towns': {'team': 'Minnesota Timberwolves', 'team_abbr': 'MIN'},
'Leonard Miller': {'team': 'Minnesota Timberwolves', 'team_abbr': 'MIN'},
'Luka Garza': {'team': 'Minnesota Timberwolves', 'team_abbr': 'MIN'},
'Dariq Whitehead': {'team': 'Brooklyn Nets', 'team_abbr': 'BKN'},
"Royce O'Neale": {'team': 'Brooklyn Nets', 'team_abbr': 'BKN'},
'Mikal Bridges': {'team': 'Brooklyn Nets', 'team_abbr': 'BKN'},
'Cameron Johnson': {'team': 'Brooklyn Nets', 'team_abbr': 'BKN'},
'Dennis Smith Jr.': {'team': 'Brooklyn Nets', 'team_abbr': 'BKN'},
'Lonnie Walker IV': {'team': 'Brooklyn Nets', 'team_abbr': 'BKN'},
'Trendon Watford': {'team': 'Brooklyn Nets', 'team_abbr': 'BKN'},
'Ben Simmons': {'team': 'Brooklyn Nets', 'team_abbr': 'BKN'},
'Armoni Brooks': {'team': 'Brooklyn Nets', 'team_abbr': 'BKN'},
'Harry Giles III': {'team': 'Brooklyn Nets', 'team_abbr': 'BKN'},
"Day'Ron Sharpe": {'team': 'Brooklyn Nets', 'team_abbr': 'BKN'},
'Noah Clowney': {'team': 'Brooklyn Nets', 'team_abbr': 'BKN'},
'Jalen Wilson': {'team': 'Brooklyn Nets', 'team_abbr': 'BKN'},
'Cam Thomas': {'team': 'Brooklyn Nets', 'team_abbr': 'BKN'},
'Spencer Dinwiddie': {'team': 'Brooklyn Nets', 'team_abbr': 'BKN'},
'Dorian Finney-Smith': {'team': 'Brooklyn Nets', 'team_abbr': 'BKN'},
'Nic Claxton': {'team': 'Brooklyn Nets', 'team_abbr': 'BKN'},
'Keon Johnson': {'team': 'Brooklyn Nets', 'team_abbr': 'BKN'},
'Dmytro Skapintsev': {'team': 'New York Knicks', 'team_abbr': 'NYK'},
'Donte DiVincenzo': {'team': 'New York Knicks', 'team_abbr': 'NYK'},
'Jacob Toppin': {'team': 'New York Knicks', 'team_abbr': 'NYK'},
'Miles McBride': {'team': 'New York Knicks', 'team_abbr': 'NYK'},
'Josh Hart': {'team': 'New York Knicks', 'team_abbr': 'NYK'},
'Immanuel Quickley': {'team': 'New York Knicks', 'team_abbr': 'NYK'},
'Quentin Grimes': {'team': 'New York Knicks', 'team_abbr': 'NYK'},
'DaQuan Jeffries': {'team': 'New York Knicks', 'team_abbr': 'NYK'},
'RJ Barrett': {'team': 'New York Knicks', 'team_abbr': 'NYK'},
'Jalen Brunson': {'team': 'New York Knicks', 'team_abbr': 'NYK'},
'Evan Fournier': {'team': 'New York Knicks', 'team_abbr': 'NYK'},
'Mitchell Robinson': {'team': 'New York Knicks', 'team_abbr': 'NYK'},
'Julius Randle': {'team': 'New York Knicks', 'team_abbr': 'NYK'},
'Charlie Brown Jr.': {'team': 'New York Knicks', 'team_abbr': 'NYK'},
'Jericho Sims': {'team': 'New York Knicks', 'team_abbr': 'NYK'},
'Ryan Arcidiacono': {'team': 'New York Knicks', 'team_abbr': 'NYK'},
'Isaiah Hartenstein': {'team': 'New York Knicks', 'team_abbr': 'NYK'},
'Taj Gibson': {'team': 'New York Knicks', 'team_abbr': 'NYK'},
'Anthony Black': {'team': 'Orlando Magic', 'team_abbr': 'ORL'},
'Jonathan Isaac': {'team': 'Orlando Magic', 'team_abbr': 'ORL'},
'Caleb Houstan': {'team': 'Orlando Magic', 'team_abbr': 'ORL'},
'Chuma Okeke': {'team': 'Orlando Magic', 'team_abbr': 'ORL'},
'Jalen Suggs': {'team': 'Orlando Magic', 'team_abbr': 'ORL'},
'Paolo Banchero': {'team': 'Orlando Magic', 'team_abbr': 'ORL'},
'Joe Ingles': {'team': 'Orlando Magic', 'team_abbr': 'ORL'},
'Kevon Harris': {'team': 'Orlando Magic', 'team_abbr': 'ORL'},
'Trevelin Queen': {'team': 'Orlando Magic', 'team_abbr': 'ORL'},
'Jett Howard': {'team': 'Orlando Magic', 'team_abbr': 'ORL'},
'Gary Harris': {'team': 'Orlando Magic', 'team_abbr': 'ORL'},
'Markelle Fultz': {'team': 'Orlando Magic', 'team_abbr': 'ORL'},
'Moritz Wagner': {'team': 'Orlando Magic', 'team_abbr': 'ORL'},
'Franz Wagner': {'team': 'Orlando Magic', 'team_abbr': 'ORL'},
'Admiral Schofield': {'team': 'Orlando Magic', 'team_abbr': 'ORL'},
'Wendell Carter Jr.': {'team': 'Orlando Magic', 'team_abbr': 'ORL'},
'Goga Bitadze': {'team': 'Orlando Magic', 'team_abbr': 'ORL'},
'Cole Anthony': {'team': 'Orlando Magic', 'team_abbr': 'ORL'},
'Tyrese Haliburton': {'team': 'Indiana Pacers', 'team_abbr': 'IND'},
'Bennedict Mathurin': {'team': 'Indiana Pacers', 'team_abbr': 'IND'},
'Obi Toppin': {'team': 'Indiana Pacers', 'team_abbr': 'IND'},
'Andrew Nembhard': {'team': 'Indiana Pacers', 'team_abbr': 'IND'},
'Jarace Walker': {'team': 'Indiana Pacers', 'team_abbr': 'IND'},
'Buddy Hield': {'team': 'Indiana Pacers', 'team_abbr': 'IND'},
'T.J. McConnell': {'team': 'Indiana Pacers', 'team_abbr': 'IND'},
'Kendall Brown': {'team': 'Indiana Pacers', 'team_abbr': 'IND'},
'Bruce Brown': {'team': 'Indiana Pacers', 'team_abbr': 'IND'},
'Jordan Nwora': {'team': 'Indiana Pacers', 'team_abbr': 'IND'},
'James Johnson': {'team': 'Indiana Pacers', 'team_abbr': 'IND'},
'Isaiah Wong': {'team': 'Indiana Pacers', 'team_abbr': 'IND'},
'Isaiah Jackson': {'team': 'Indiana Pacers', 'team_abbr': 'IND'},
'Aaron Nesmith': {'team': 'Indiana Pacers', 'team_abbr': 'IND'},
'Jalen Smith': {'team': 'Indiana Pacers', 'team_abbr': 'IND'},
'Ben Sheppard': {'team': 'Indiana Pacers', 'team_abbr': 'IND'},
'Myles Turner': {'team': 'Indiana Pacers', 'team_abbr': 'IND'},
'Oscar Tshiebwe': {'team': 'Indiana Pacers', 'team_abbr': 'IND'},
'Tyrese Maxey': {'team': 'Philadelphia 76ers', 'team_abbr': 'PHI'},
'KJ Martin': {'team': 'Philadelphia 76ers', 'team_abbr': 'PHI'},
'Marcus Morris Sr.': {'team': 'Philadelphia 76ers', 'team_abbr': 'PHI'},
'Mo Bamba': {'team': 'Philadelphia 76ers', 'team_abbr': 'PHI'},
"De'Anthony Melton": {'team': 'Philadelphia 76ers', 'team_abbr': 'PHI'},
'Kelly Oubre Jr.': {'team': 'Philadelphia 76ers', 'team_abbr': 'PHI'},
'Jaden Springer': {'team': 'Philadelphia 76ers', 'team_abbr': 'PHI'},
'Tobias Harris': {'team': 'Philadelphia 76ers', 'team_abbr': 'PHI'},
'Ricky Council IV': {'team': 'Philadelphia 76ers', 'team_abbr': 'PHI'},
'Joel Embiid': {'team': 'Philadelphia 76ers', 'team_abbr': 'PHI'},
'Patrick Beverley': {'team': 'Philadelphia 76ers', 'team_abbr': 'PHI'},
'Terquavion Smith': {'team': 'Philadelphia 76ers', 'team_abbr': 'PHI'},
'Danuel House Jr.': {'team': 'Philadelphia 76ers', 'team_abbr': 'PHI'},
'Furkan Korkmaz': {'team': 'Philadelphia 76ers', 'team_abbr': 'PHI'},
'Robert Covington': {'team': 'Philadelphia 76ers', 'team_abbr': 'PHI'},
'Nicolas Batum': {'team': 'Philadelphia 76ers', 'team_abbr': 'PHI'},
'Paul Reed': {'team': 'Philadelphia 76ers', 'team_abbr': 'PHI'},
'Jordan Goodwin': {'team': 'Phoenix Suns', 'team_abbr': 'PHX'},
'Devin Booker': {'team': 'Phoenix Suns', 'team_abbr': 'PHX'},
'Josh Okogie': {'team': 'Phoenix Suns', 'team_abbr': 'PHX'},
'Bradley Beal': {'team': 'Phoenix Suns', 'team_abbr': 'PHX'},
'Chimezie Metu': {'team': 'Phoenix Suns', 'team_abbr': 'PHX'},
'Grayson Allen': {'team': 'Phoenix Suns', 'team_abbr': 'PHX'},
'Theo Maledon': {'team': 'Phoenix Suns', 'team_abbr': 'PHX'},
'Damion Lee': {'team': 'Phoenix Suns', 'team_abbr': 'PHX'},
'Bol Bol': {'team': 'Phoenix Suns', 'team_abbr': 'PHX'},
'Drew Eubanks': {'team': 'Phoenix Suns', 'team_abbr': 'PHX'},
'Yuta Watanabe': {'team': 'Phoenix Suns', 'team_abbr': 'PHX'},
'Jusuf Nurkic': {'team': 'Phoenix Suns', 'team_abbr': 'PHX'},
'Keita Bates-Diop': {'team': 'Phoenix Suns', 'team_abbr': 'PHX'},
'Eric Gordon': {'team': 'Phoenix Suns', 'team_abbr': 'PHX'},
'Nassir Little': {'team': 'Phoenix Suns', 'team_abbr': 'PHX'},
'Udoka Azubuike': {'team': 'Phoenix Suns', 'team_abbr': 'PHX'},
'Kevin Durant': {'team': 'Phoenix Suns', 'team_abbr': 'PHX'},
'Saben Lee': {'team': 'Phoenix Suns', 'team_abbr': 'PHX'},
'Scoot Henderson': {'team': 'Portland Trail Blazers', 'team_abbr': 'POR'},
'Anfernee Simons': {'team': 'Portland Trail Blazers', 'team_abbr': 'POR'},
'Deandre Ayton': {'team': 'Portland Trail Blazers', 'team_abbr': 'POR'},
'Matisse Thybulle': {'team': 'Portland Trail Blazers', 'team_abbr': 'POR'},
'Skylar Mays': {'team': 'Portland Trail Blazers', 'team_abbr': 'POR'},
'Kris Murray': {'team': 'Portland Trail Blazers', 'team_abbr': 'POR'},
'Jerami Grant': {'team': 'Portland Trail Blazers', 'team_abbr': 'POR'},
'Moses Brown': {'team': 'Portland Trail Blazers', 'team_abbr': 'POR'},
'Malcolm Brogdon': {'team': 'Portland Trail Blazers', 'team_abbr': 'POR'},
'Shaedon Sharpe': {'team': 'Portland Trail Blazers', 'team_abbr': 'POR'},
'Ish Wainright': {'team': 'Portland Trail Blazers', 'team_abbr': 'POR'},
'Justin Minaya': {'team': 'Portland Trail Blazers', 'team_abbr': 'POR'},
'Duop Reath': {'team': 'Portland Trail Blazers', 'team_abbr': 'POR'},
'Toumani Camara': {'team': 'Portland Trail Blazers', 'team_abbr': 'POR'},
'Jabari Walker': {'team': 'Portland Trail Blazers', 'team_abbr': 'POR'},
'Robert Williams III': {'team': 'Portland Trail Blazers', 'team_abbr': 'POR'},
'Ibou Badji': {'team': 'Portland Trail Blazers', 'team_abbr': 'POR'},
'Rayan Rupert': {'team': 'Portland Trail Blazers', 'team_abbr': 'POR'},
'Malik Monk': {'team': 'Sacramento Kings', 'team_abbr': 'SAC'},
'JaVale McGee': {'team': 'Sacramento Kings', 'team_abbr': 'SAC'},
'Chris Duarte': {'team': 'Sacramento Kings', 'team_abbr': 'SAC'},
"De'Aaron Fox": {'team': 'Sacramento Kings', 'team_abbr': 'SAC'},
'Sasha Vezenkov': {'team': 'Sacramento Kings', 'team_abbr': 'SAC'},
'Kevin Huerter': {'team': 'Sacramento Kings', 'team_abbr': 'SAC'},
'Domantas Sabonis': {'team': 'Sacramento Kings', 'team_abbr': 'SAC'},
'Keegan Murray': {'team': 'Sacramento Kings', 'team_abbr': 'SAC'},
'Davion Mitchell': {'team': 'Sacramento Kings', 'team_abbr': 'SAC'},
'Kessler Edwards': {'team': 'Sacramento Kings', 'team_abbr': 'SAC'},
'Jalen Slawson': {'team': 'Sacramento Kings', 'team_abbr': 'SAC'},
'Colby Jones': {'team': 'Sacramento Kings', 'team_abbr': 'SAC'},
'Keon Ellis': {'team': 'Sacramento Kings', 'team_abbr': 'SAC'},
'Alex Len': {'team': 'Sacramento Kings', 'team_abbr': 'SAC'},
'Jordan Ford': {'team': 'Sacramento Kings', 'team_abbr': 'SAC'},
'Harrison Barnes': {'team': 'Sacramento Kings', 'team_abbr': 'SAC'},
'Trey Lyles': {'team': 'Sacramento Kings', 'team_abbr': 'SAC'},
'Juan Toscano-Anderson': {'team': 'Sacramento Kings', 'team_abbr': 'SAC'},
'Victor Wembanyama': {'team': 'San Antonio Spurs', 'team_abbr': 'SAS'},
'Keldon Johnson': {'team': 'San Antonio Spurs', 'team_abbr': 'SAS'},
"Devonte' Graham": {'team': 'San Antonio Spurs', 'team_abbr': 'SAS'},
'Jeremy Sochan': {'team': 'San Antonio Spurs', 'team_abbr': 'SAS'},
'Blake Wesley': {'team': 'San Antonio Spurs', 'team_abbr': 'SAS'},
'Cedi Osman': {'team': 'San Antonio Spurs', 'team_abbr': 'SAS'},
'Doug McDermott': {'team': 'San Antonio Spurs', 'team_abbr': 'SAS'},
'Malaki Branham': {'team': 'San Antonio Spurs', 'team_abbr': 'SAS'},
'Zach Collins': {'team': 'San Antonio Spurs', 'team_abbr': 'SAS'},
'Devin Vassell': {'team': 'San Antonio Spurs', 'team_abbr': 'SAS'},
'Sidy Cissoko': {'team': 'San Antonio Spurs', 'team_abbr': 'SAS'},
'Dominick Barlow': {'team': 'San Antonio Spurs', 'team_abbr': 'SAS'},
'Charles Bediako': {'team': 'San Antonio Spurs', 'team_abbr': 'SAS'},
'Charles Bassey': {'team': 'San Antonio Spurs', 'team_abbr': 'SAS'},
'David Duke Jr.': {'team': 'San Antonio Spurs', 'team_abbr': 'SAS'},
'Julian Champagnie': {'team': 'San Antonio Spurs', 'team_abbr': 'SAS'},
'Tre Jones': {'team': 'San Antonio Spurs', 'team_abbr': 'SAS'},
'Sandro Mamukelashvili': {'team': 'San Antonio Spurs', 'team_abbr': 'SAS'},
'Shai Gilgeous-Alexander': {'team': 'Oklahoma City Thunder',
 'team_abbr': 'OKC'},
'Josh Giddey': {'team': 'Oklahoma City Thunder', 'team_abbr': 'OKC'},
'Luguentz Dort': {'team': 'Oklahoma City Thunder', 'team_abbr': 'OKC'},
'Jaylin Williams': {'team': 'Oklahoma City Thunder', 'team_abbr': 'OKC'},
'Chet Holmgren': {'team': 'Oklahoma City Thunder', 'team_abbr': 'OKC'},
'Jalen Williams': {'team': 'Oklahoma City Thunder', 'team_abbr': 'OKC'},
'Davis Bertans': {'team': 'Oklahoma City Thunder', 'team_abbr': 'OKC'},
'Isaiah Joe': {'team': 'Oklahoma City Thunder', 'team_abbr': 'OKC'},
'Lindy Waters III': {'team': 'Oklahoma City Thunder', 'team_abbr': 'OKC'},
'Ousmane Dieng': {'team': 'Oklahoma City Thunder', 'team_abbr': 'OKC'},
'Aleksej Pokusevski': {'team': 'Oklahoma City Thunder', 'team_abbr': 'OKC'},
'Keyontae Johnson': {'team': 'Oklahoma City Thunder', 'team_abbr': 'OKC'},
'Aaron Wiggins': {'team': 'Oklahoma City Thunder', 'team_abbr': 'OKC'},
'Cason Wallace': {'team': 'Oklahoma City Thunder', 'team_abbr': 'OKC'},
'Tre Mann': {'team': 'Oklahoma City Thunder', 'team_abbr': 'OKC'},
'Vasilije Micic': {'team': 'Oklahoma City Thunder', 'team_abbr': 'OKC'},
'Olivier Sarr': {'team': 'Oklahoma City Thunder', 'team_abbr': 'OKC'},
'Kenrich Williams': {'team': 'Oklahoma City Thunder', 'team_abbr': 'OKC'},
'Javon Freeman-Liberty': {'team': 'Toronto Raptors', 'team_abbr': 'TOR'},
'Gradey Dick': {'team': 'Toronto Raptors', 'team_abbr': 'TOR'},
'Jalen McDaniels': {'team': 'Toronto Raptors', 'team_abbr': 'TOR'},
'OG Anunoby': {'team': 'Toronto Raptors', 'team_abbr': 'TOR'},
'Scottie Barnes': {'team': 'Toronto Raptors', 'team_abbr': 'TOR'},
'Jontay Porter': {'team': 'Toronto Raptors', 'team_abbr': 'TOR'},
'Precious Achiuwa': {'team': 'Toronto Raptors', 'team_abbr': 'TOR'},
'Garrett Temple': {'team': 'Toronto Raptors', 'team_abbr': 'TOR'},
'Dennis Schroder': {'team': 'Toronto Raptors', 'team_abbr': 'TOR'},
'Jakob Poeltl': {'team': 'Toronto Raptors', 'team_abbr': 'TOR'},
'Thaddeus Young': {'team': 'Toronto Raptors', 'team_abbr': 'TOR'},
'Malachi Flynn': {'team': 'Toronto Raptors', 'team_abbr': 'TOR'},
'Markquis Nowell': {'team': 'Toronto Raptors', 'team_abbr': 'TOR'},
'Chris Boucher': {'team': 'Toronto Raptors', 'team_abbr': 'TOR'},
'Otto Porter Jr.': {'team': 'Toronto Raptors', 'team_abbr': 'TOR'},
'Gary Trent Jr.': {'team': 'Toronto Raptors', 'team_abbr': 'TOR'},
'Christian Koloko': {'team': 'Toronto Raptors', 'team_abbr': 'TOR'},
'Pascal Siakam': {'team': 'Toronto Raptors', 'team_abbr': 'TOR'},
'Jordan Clarkson': {'team': 'Utah Jazz', 'team_abbr': 'UTA'},
'Taylor Hendricks': {'team': 'Utah Jazz', 'team_abbr': 'UTA'},
'Collin Sexton': {'team': 'Utah Jazz', 'team_abbr': 'UTA'},
'Keyonte George': {'team': 'Utah Jazz', 'team_abbr': 'UTA'},
'Talen Horton-Tucker': {'team': 'Utah Jazz', 'team_abbr': 'UTA'},
'Brice Sensabaugh': {'team': 'Utah Jazz', 'team_abbr': 'UTA'},
'Kris Dunn': {'team': 'Utah Jazz', 'team_abbr': 'UTA'},
'Josh Christopher': {'team': 'Utah Jazz', 'team_abbr': 'UTA'},
'Simone Fontecchio': {'team': 'Utah Jazz', 'team_abbr': 'UTA'},
'Luka Samanic': {'team': 'Utah Jazz', 'team_abbr': 'UTA'},
'John Collins': {'team': 'Utah Jazz', 'team_abbr': 'UTA'},
'Lauri Markkanen': {'team': 'Utah Jazz', 'team_abbr': 'UTA'},
'Walker Kessler': {'team': 'Utah Jazz', 'team_abbr': 'UTA'},
'Micah Potter': {'team': 'Utah Jazz', 'team_abbr': 'UTA'},
'Ochai Agbaji': {'team': 'Utah Jazz', 'team_abbr': 'UTA'},
'Johnny Juzang': {'team': 'Utah Jazz', 'team_abbr': 'UTA'},
'Kelly Olynyk': {'team': 'Utah Jazz', 'team_abbr': 'UTA'},
'Omer Yurtseven': {'team': 'Utah Jazz', 'team_abbr': 'UTA'},
'Jacob Gilyard': {'team': 'Memphis Grizzlies', 'team_abbr': 'MEM'},
'Xavier Tillman': {'team': 'Memphis Grizzlies', 'team_abbr': 'MEM'},
'Jake LaRavia': {'team': 'Memphis Grizzlies', 'team_abbr': 'MEM'},
'Steven Adams': {'team': 'Memphis Grizzlies', 'team_abbr': 'MEM'},
'Vince Williams Jr.': {'team': 'Memphis Grizzlies', 'team_abbr': 'MEM'},
'Santi Aldama': {'team': 'Memphis Grizzlies', 'team_abbr': 'MEM'},
'Ziaire Williams': {'team': 'Memphis Grizzlies', 'team_abbr': 'MEM'},
'Luke Kennard': {'team': 'Memphis Grizzlies', 'team_abbr': 'MEM'},
'Ja Morant': {'team': 'Memphis Grizzlies', 'team_abbr': 'MEM'},
'Jaren Jackson Jr.': {'team': 'Memphis Grizzlies', 'team_abbr': 'MEM'},
'Brandon Clarke': {'team': 'Memphis Grizzlies', 'team_abbr': 'MEM'},
'Bismack Biyombo': {'team': 'Memphis Grizzlies', 'team_abbr': 'MEM'},
'David Roddy': {'team': 'Memphis Grizzlies', 'team_abbr': 'MEM'},
'Desmond Bane': {'team': 'Memphis Grizzlies', 'team_abbr': 'MEM'},
'Derrick Rose': {'team': 'Memphis Grizzlies', 'team_abbr': 'MEM'},
'Marcus Smart': {'team': 'Memphis Grizzlies', 'team_abbr': 'MEM'},
'GG Jackson': {'team': 'Memphis Grizzlies', 'team_abbr': 'MEM'},
'John Konchar': {'team': 'Memphis Grizzlies', 'team_abbr': 'MEM'},
'Bilal Coulibaly': {'team': 'Washington Wizards', 'team_abbr': 'WAS'},
'Johnny Davis': {'team': 'Washington Wizards', 'team_abbr': 'WAS'},
'Jared Butler': {'team': 'Washington Wizards', 'team_abbr': 'WAS'},
'Tyus Jones': {'team': 'Washington Wizards', 'team_abbr': 'WAS'},
'Patrick Baldwin Jr.': {'team': 'Washington Wizards', 'team_abbr': 'WAS'},
'Deni Avdija': {'team': 'Washington Wizards', 'team_abbr': 'WAS'},
'Ryan Rollins': {'team': 'Washington Wizards', 'team_abbr': 'WAS'},
'Jordan Poole': {'team': 'Washington Wizards', 'team_abbr': 'WAS'},
'Anthony Gill': {'team': 'Washington Wizards', 'team_abbr': 'WAS'},
'Landry Shamet': {'team': 'Washington Wizards', 'team_abbr': 'WAS'},
'Daniel Gafford': {'team': 'Washington Wizards', 'team_abbr': 'WAS'},
'Corey Kispert': {'team': 'Washington Wizards', 'team_abbr': 'WAS'},
'Jules Bernard': {'team': 'Washington Wizards', 'team_abbr': 'WAS'},
'Kyle Kuzma': {'team': 'Washington Wizards', 'team_abbr': 'WAS'},
'Mike Muscala': {'team': 'Washington Wizards', 'team_abbr': 'WAS'},
'Delon Wright': {'team': 'Washington Wizards', 'team_abbr': 'WAS'},
'Danilo Gallinari': {'team': 'Washington Wizards', 'team_abbr': 'WAS'},
'Eugene Omoruyi': {'team': 'Washington Wizards', 'team_abbr': 'WAS'},
'Jalen Duren': {'team': 'Detroit Pistons', 'team_abbr': 'DET'},
'Cade Cunningham': {'team': 'Detroit Pistons', 'team_abbr': 'DET'},
'Monte Morris': {'team': 'Detroit Pistons', 'team_abbr': 'DET'},
'Killian Hayes': {'team': 'Detroit Pistons', 'team_abbr': 'DET'},
'Jared Rhoden': {'team': 'Detroit Pistons', 'team_abbr': 'DET'},
'Ausar Thompson': {'team': 'Detroit Pistons', 'team_abbr': 'DET'},
'Isaiah Livers': {'team': 'Detroit Pistons', 'team_abbr': 'DET'},
'James Wiseman': {'team': 'Detroit Pistons', 'team_abbr': 'DET'},
'Alec Burks': {'team': 'Detroit Pistons', 'team_abbr': 'DET'},
'Stanley Umude': {'team': 'Detroit Pistons', 'team_abbr': 'DET'},
'Malcolm Cazalon': {'team': 'Detroit Pistons', 'team_abbr': 'DET'},
'Jaden Ivey': {'team': 'Detroit Pistons', 'team_abbr': 'DET'},
'Kevin Knox II': {'team': 'Detroit Pistons', 'team_abbr': 'DET'},
'Marcus Sasser': {'team': 'Detroit Pistons', 'team_abbr': 'DET'},
'Isaiah Stewart': {'team': 'Detroit Pistons', 'team_abbr': 'DET'},
'Joe Harris': {'team': 'Detroit Pistons', 'team_abbr': 'DET'},
'Marvin Bagley III': {'team': 'Detroit Pistons', 'team_abbr': 'DET'},
'Bojan Bogdanovic': {'team': 'Detroit Pistons', 'team_abbr': 'DET'},
'Nathan Mensah': {'team': 'Charlotte Hornets', 'team_abbr': 'CHA'},
'Miles Bridges': {'team': 'Charlotte Hornets', 'team_abbr': 'CHA'},
'LaMelo Ball': {'team': 'Charlotte Hornets', 'team_abbr': 'CHA'},
'James Bouknight': {'team': 'Charlotte Hornets', 'team_abbr': 'CHA'},
'Terry Rozier': {'team': 'Charlotte Hornets', 'team_abbr': 'CHA'},
'Nick Richards': {'team': 'Charlotte Hornets', 'team_abbr': 'CHA'},
'Mark Williams': {'team': 'Charlotte Hornets', 'team_abbr': 'CHA'},
'Bryce McGowens': {'team': 'Charlotte Hornets', 'team_abbr': 'CHA'},
'Nick Smith Jr.': {'team': 'Charlotte Hornets', 'team_abbr': 'CHA'},
'Amari Bailey': {'team': 'Charlotte Hornets', 'team_abbr': 'CHA'},
'Cody Martin': {'team': 'Charlotte Hornets', 'team_abbr': 'CHA'},
'Leaky Black': {'team': 'Charlotte Hornets', 'team_abbr': 'CHA'},
'Ish Smith': {'team': 'Charlotte Hornets', 'team_abbr': 'CHA'},
'Gordon Hayward': {'team': 'Charlotte Hornets', 'team_abbr': 'CHA'},
'JT Thor': {'team': 'Charlotte Hornets', 'team_abbr': 'CHA'},
'Brandon Miller': {'team': 'Charlotte Hornets', 'team_abbr': 'CHA'},
'P.J. Washington': {'team': 'Charlotte Hornets', 'team_abbr': 'CHA'},
'Frank Ntilikina': {'team': 'Charlotte Hornets', 'team_abbr': 'CHA'}}

export const TEAM_COLORS = {'ATL': {'main_color': 'red', 'secondary_color': 'white'}, 'BKN': {'main_color': 'black', 'secondary_color': 'white'}, 'BOS': {'main_color': 'green', 'secondary_color': 'white'}, 'CHA': {'main_color': 'mediumpurple', 'secondary_color': 'teal'}, 'CHI': {'main_color': 'red', 'secondary_color': 'black'}, 'CLE': {'main_color': 'wine', 'secondary_color': 'gold'}, 'DAL': {'main_color': 'lightblue', 'secondary_color': 'silver'}, 'DEN': {'main_color': 'lightblue', 'secondary_color': 'gold'}, 'DET': {'main_color': 'lightblue', 'secondary_color': 'red'}, 'GSW': {'main_color': 'gold', 'secondary_color': 'lightblue'}, 'HOU': {'main_color': 'red', 'secondary_color': 'silver'}, 'IND': {'main_color': 'lightblue', 'secondary_color': 'gold'}, 'LAC': {'main_color': 'red', 'secondary_color': 'lightblue'}, 'LAL': {'main_color': 'mediumpurple', 'secondary_color': 'gold'}, 'MEM': {'main_color': 'midnightlightblue', 'secondary_color': 'bealeStreetlightblue'}, 'MIA': {'main_color': 'red', 'secondary_color': 'black'}, 'MIL': {'main_color': 'green', 'secondary_color': 'cream'}, 'MIN': {'main_color': 'lightblue', 'secondary_color': 'green'}, 'NOP': {'main_color': 'lightblue', 'secondary_color': 'red'}, 'NYK': {'main_color': 'lightblue', 'secondary_color': 'orange'}, 'OKC': {'main_color': 'lightblue', 'secondary_color': 'orange'}, 'ORL': {'main_color': 'lightblue', 'secondary_color': 'silver'}, 'PHI': {'main_color': 'lightblue', 'secondary_color': 'red'}, 'PHX': {'main_color': 'orange', 'secondary_color': 'mediumpurple'}, 'POR': {'main_color': 'red', 'secondary_color': 'black'}, 'SAC': {'main_color': 'mediumpurple', 'secondary_color': 'silver'}, 'SAS': {'main_color': 'silver', 'secondary_color': 'black'}, 'TOR': {'main_color': 'red', 'secondary_color': 'silver'}, 'UTA': {'main_color': 'yellow', 'secondary_color': 'yellow'}, 'WAS': {'main_color': 'yellow', 'secondary_color': 'red'}}

export const PLAYER_TO_ID = {'Precious Achiuwa': '1630173',
'Steven Adams': '203500',
'Bam Adebayo': '1628389',
'Ochai Agbaji': '1630534',
'Santi Aldama': '1630583',
'Nickeil Alexander-Walker': '1629638',
'Grayson Allen': '1628960',
'Jarrett Allen': '1628386',
'Jose Alvarado': '1630631',
'Kyle Anderson': '203937',
'Giannis Antetokounmpo': '203507',
'Thanasis Antetokounmpo': '203648',
'Cole Anthony': '1630175',
'OG Anunoby': '1628384',
'Ryan Arcidiacono': '1627853',
'Deni Avdija': '1630166',
'Deandre Ayton': '1629028',
'Udoka Azubuike': '1628962',
'Marvin Bagley III': '1628963',
'Amari Bailey': '1641735',
'Patrick Baldwin Jr.': '1631116',
'LaMelo Ball': '1630163',
'Lonzo Ball': '1628366',
'Mo Bamba': '1628964',
'Paolo Banchero': '1631094',
'Desmond Bane': '1630217',
'Dalano Banton': '1630625',
'Dominick Barlow': '1631230',
'Harrison Barnes': '203084',
'Scottie Barnes': '1630567',
'RJ Barrett': '1629628',
'Charles Bassey': '1629646',
'Emoni Bates': '1641734',
'Keita Bates-Diop': '1628966',
'Nicolas Batum': '201587',
'Bradley Beal': '203078',
'Malik Beasley': '1627736',
'MarJon Beauchamp': '1630699',
'Charles Bediako': '1641777',
'Davis Bertans': '202722',
'Patrick Beverley': '201976',
'Saddiq Bey': '1630180',
'Goga Bitadze': '1629048',
'Onuralp Bitim': '1641931',
'Bismack Biyombo': '202687',
'Anthony Black': '1641710',
'Leaky Black': '1641778',
'Bogdan Bogdanovic': '203992',
'Bojan Bogdanovic': '202711',
'Bol Bol': '1629626',
'Marques Bolden': '1629716',
'Devin Booker': '1626164',
'Brandon Boston Jr.': '1630527',
'Chris Boucher': '1628449',
'James Bouknight': '1630547',
'Malaki Branham': '1631103',
'Christian Braun': '1631128',
'Mikal Bridges': '1628969',
'Miles Bridges': '1628970',
'Oshae Brissett': '1629052',
'Malcolm Brogdon': '1627763',
'Armoni Brooks': '1629717',
'Dillon Brooks': '1628415',
'Bruce Brown': '1628971',
'Jaylen Brown': '1627759',
'Kendall Brown': '1631112',
'Kobe Brown': '1641738',
'Moses Brown': '1629650',
'Greg Brown III': '1630535',
'Charlie Brown Jr.': '1629718',
'Troy Brown Jr.': '1628972',
'Jalen Brunson': '1628973',
'Thomas Bryant': '1628418',
'Kobe Bufkin': '1641723',
'Reggie Bullock Jr.': '203493',
'Alec Burks': '202692',
'Jared Butler': '1630215',
'Jimmy Butler': '202710',
'John Butler Jr.': '1631219',
'Jamal Cain': '1631288',
'Kentavious Caldwell-Pope': '203484',
'Toumani Camara': '1641739',
'Vlatko Cancar': '1628427',
'Clint Capela': '203991',
'Jevon Carter': '1628975',
'Wendell Carter Jr.': '1628976',
'Alex Caruso': '1627936',
'Colin Castleton': '1630658',
'Malcolm Cazalon': '1630608',
'Julian Champagnie': '1630577',
'Max Christie': '1631108',
'Josh Christopher': '1630528',
'Sidy Cissoko': '1631321',
'Jaylen Clark': '1641740',
'Brandon Clarke': '1629634',
'Jordan Clarkson': '203903',
'Nic Claxton': '1629651',
'Noah Clowney': '1641730',
'Amir Coffey': '1629599',
'John Collins': '1628381',
'Zach Collins': '1628380',
'Mike Conley': '201144',
'Pat Connaughton': '1626192',
'Bilal Coulibaly': '1641731',
'Ricky Council IV': '1641741',
'Robert Covington': '203496',
'Torrey Craig': '1628470',
'Jae Crowder': '203109',
'Cade Cunningham': '1630595',
'Seth Curry': '203552',
'Stephen Curry': '201939',
'Dyson Daniels': '1630700',
'Anthony Davis': '203076',
'Johnny Davis': '1631098',
'JD Davison': '1631120',
'DeMar DeRozan': '201942',
'Dexter Dennis': '1641926',
'Donte DiVincenzo': '1628978',
'Moussa Diabate': '1631217',
'Gradey Dick': '1641711',
'Ousmane Dieng': '1631172',
'Spencer Dinwiddie': '203915',
'Luka Doncic': '1629029',
'Luguentz Dort': '1629652',
'Ayo Dosunmu': '1630245',
'Andre Drummond': '203083',
'Chris Duarte': '1630537',
'Kris Dunn': '1627739',
'Kevin Durant': '201142',
'Jalen Duren': '1631105',
'Tari Eason': '1631106',
'Anthony Edwards': '1630162',
'Kessler Edwards': '1630556',
'Keon Ellis': '1631165',
'Joel Embiid': '203954',
'Drew Eubanks': '1629234',
'Dante Exum': '203957',
'Bruno Fernando': '1628981',
'Dorian Finney-Smith': '1627827',
'Malachi Flynn': '1630201',
'Simone Fontecchio': '1631323',
'Jordan Ford': '1630259',
'Trent Forrest': '1630235',
'Evan Fournier': '203095',
"De'Aaron Fox": '1628368',
'Javon Freeman-Liberty': '1631241',
'Alex Fudge': '1641788',
'Markelle Fultz': '1628365',
'Daniel Gafford': '1629655',
'Danilo Gallinari': '201568',
'Darius Garland': '1629636',
'Usman Garuba': '1630586',
'Luka Garza': '1630568',
'Kaiser Gates': '1629232',
'Keyonte George': '1641718',
'Paul George': '202331',
'Josh Giddey': '1630581',
'Harry Giles III': '1628385',
'Shai Gilgeous-Alexander': '1628983',
'Anthony Gill': '1630264',
'Collin Gillespie': '1631221',
'Jacob Gilyard': '1631367',
'Rudy Gobert': '203497',
'Jordan Goodwin': '1630692',
'Aaron Gordon': '203932',
'Eric Gordon': '201569',
"Devonte' Graham": '1628984',
'Jerami Grant': '203924',
'AJ Green': '1631260',
'Danny Green': '201980',
'Draymond Green': '203110',
'Jalen Green': '1630224',
'Jeff Green': '201145',
'Josh Green': '1630182',
'AJ Griffin': '1631100',
'Quentin Grimes': '1629656',
'Mouhamed Gueye': '1631243',
'Rui Hachimura': '1629060',
'Tyrese Haliburton': '1630169',
'R.J. Hampton': '1630181',
'Tim Hardaway Jr.': '203501',
'James Harden': '201935',
'Jaden Hardy': '1630702',
'Ron Harper Jr.': '1631199',
'Gary Harris': '203914',
'Joe Harris': '203925',
'Kevon Harris': '1630284',
'Tobias Harris': '202699',
'Josh Hart': '1628404',
'Isaiah Hartenstein': '1628392',
'Sam Hauser': '1630573',
'Jordan Hawkins': '1641722',
'Jaxson Hayes': '1629637',
'Killian Hayes': '1630165',
'Gordon Hayward': '202330',
'Scoot Henderson': '1630703',
'Taylor Hendricks': '1641707',
'Tyler Herro': '1629639',
'Buddy Hield': '1627741',
'Haywood Highsmith': '1629312',
'Nate Hinton': '1630207',
"D'Moi Hodge": '1641793',
'Aaron Holiday': '1628988',
'Jrue Holiday': '201950',
'Justin Holiday': '203200',
'Richaun Holmes': '1626158',
'Chet Holmgren': '1631096',
'Jalen Hood-Schifino': '1641720',
'Al Horford': '201143',
'Talen Horton-Tucker': '1629659',
'Danuel House Jr.': '1627863',
'Caleb Houstan': '1631216',
'Jett Howard': '1641724',
'Kevin Huerter': '1628989',
'Jay Huff': '1630643',
"De'Andre Hunter": '1629631',
'Bones Hyland': '1630538',
'Joe Ingles': '204060',
'Brandon Ingram': '1627742',
'Kyrie Irving': '202681',
'Jonathan Isaac': '1628371',
'Jaden Ivey': '1631093',
'GG Jackson': '1641713',
'Isaiah Jackson': '1630543',
'Reggie Jackson': '202704',
'Andre Jackson Jr.': '1641748',
'Jaren Jackson Jr.': '1628991',
'Trayce Jackson-Davis': '1631218',
'Lebron James': '2544',
'Jaime Jaquez Jr.': '1631170',
'Daquan Jeffries': '1629610',
'Ty Jerome': '1629660',
'Isaiah Joe': '1630198',
'Cameron Johnson': '1629661',
'Jalen Johnson': '1630552',
'Keldon Johnson': '1629640',
'Keon Johnson': '1630553',
'Keyontae Johnson': '1641749',
'Nikola Jokic': '203999',
'Colby Jones': '1641732',
'Damian Jones': '1627745',
'Herbert Jones': '1630529',
'Tre Jones': '1630200',
'Tyus Jones': '1626145',
'Derrick Jones Jr.': '1627884',
'DeAndre Jordan': '201599',
'Cory Joseph': '202709',
'Nikola Jovic': '1631107',
'Johnny Juzang': '1630548',
'Luke Kennard': '1628379',
'Walker Kessler': '1631117',
'Braxton Key': '1630296',
'Corey Kispert': '1630557',
'Maxi Kleber': '1628467',
'Nathan Knight': '1630233',
'Kevin Knox II': '1628995',
'Christian Koloko': '1631132',
'John Konchar': '1629723',
'Furkan Korkmaz': '1627788',
'Luke Kornet': '1628436',
'Jonathan Kuminga': '1630228',
'Kyle Kuzma': '1628398',
'Jake LaRavia': '1631222',
'Zach LaVine': '203897',
'Jock Landale': '1629111',
'A.J. Lawson': '1630639',
'Caris LeVert': '1627747',
'Damion Lee': '1627814',
'Saben Lee': '1630240',
'Alex Len': '203458',
'Kawhi Leonard': '202695',
'Justin Lewis': '1631171',
'Maxwell Lewis': '1641721',
'Kira Lewis Jr.': '1630184',
'E.J. Liddell': '1630604',
'Damian Lillard': '203081',
'Nassir Little': '1629642',
'Dereck Lively II': '1641726',
'Isaiah Livers': '1630587',
'Chris Livingston': '1641753',
'Kenneth Lofton Jr.': '1631254',
'Kevon Looney': '1626172',
'Brook Lopez': '201572',
'Robin Lopez': '201577',
'Kevin Love': '201567',
'Kyle Lowry': '200768',
'Seth Lundy': '1641754',
'Trey Lyles': '1626168',
'Theo Maledon': '1630177',
'Sandro Mamukelashvili': '1630572',
'Terance Mann': '1629611',
'Tre Mann': '1630544',
'Boban Marjanovic': '1626246',
'Lauri Markkanen': '1628374',
'Naji Marshall': '1630230',
'Caleb Martin': '1628997',
'Cody Martin': '1628998',
'KJ Martin': '1630231',
'Garrison Mathews': '1629726',
'Bennedict Mathurin': '1631097',
'Wesley Matthews': '202083',
'Tyrese Maxey': '1630178',
'Skylar Mays': '1630219',
'Miles McBride': '1630540',
'CJ McCollum': '203468',
'T.J. McConnell': '204456',
'Jaden McDaniels': '1630183',
'Jalen McDaniels': '1629667',
'Doug McDermott': '203926',
'JaVale McGee': '201580',
'Bryce McGowens': '1631121',
'Jordan McLaughlin': '1629162',
"De'Anthony Melton": '1629001',
'Sam Merrill': '1630241',
'Chimezie Metu': '1629002',
'Vasilije Micic': '203995',
'Khris Middleton': '203114',
'Brandon Miller': '1641706',
'Jordan Miller': '1641757',
'Leonard Miller': '1631159',
'Patty Mills': '201988',
'Shake Milton': '1629003',
'Justin Minaya': '1631303',
'Josh Minott': '1631169',
'Davion Mitchell': '1630558',
'Donovan Mitchell': '1628378',
'Evan Mobley': '1630596',
'Isaiah Mobley': '1630600',
'Malik Monk': '1628370',
'Moses Moody': '1630541',
'Wendell Moore Jr.': '1631111',
'Ja Morant': '1629630',
'Markieff Morris': '202693',
'Monte Morris': '1628420',
'Marcus Morris Sr.': '202694',
'Trey Murphy III': '1630530',
'Dejounte Murray': '1627749',
'Jamal Murray': '1627750',
'Keegan Murray': '1631099',
'Kris Murray': '1631200',
'Mike Muscala': '203488',
'Svi Mykhailiuk': '1629004',
'Larry Nance Jr.': '1626204',
'Andrew Nembhard': '1629614',
'Aaron Nesmith': '1630174',
'Georges Niang': '1627777',
'Daishen Nix': '1630227',
'Zeke Nnaji': '1630192',
'Miles Norris': '1641936',
'Markquis Nowell': '1641806',
'Frank Ntilikina': '1628373',
'Jusuf Nurkic': '203994',
'Jordan Nwora': '1629670',
"Royce O'Neale": '1626220',
'Chuma Okeke': '1629643',
'Josh Okogie': '1629006',
'Onyeka Okongwu': '1630168',
'Isaac Okoro': '1630171',
'Victor Oladipo': '203506',
'Kelly Olynyk': '203482',
'Eugene Omoruyi': '1630647',
'Cedi Osman': '1626224',
'Kelly Oubre Jr.': '1626162',
'Chris Paul': '101108',
'Cameron Payne': '1626166',
'Gary Payton II': '1627780',
'Filip Petrusev': '1630196',
'Julian Phillips': '1641763',
'Jalen Pickett': '1629618',
'Mason Plumlee': '203486',
'Brandin Podziemski': '1641764',
'Jakob Poeltl': '1627751',
'Aleksej Pokusevski': '1630197',
'Jordan Poole': '1629673',
'Craig Porter': '1641854',
'Michael Porter Jr.': '1629008',
'Otto Porter Jr.': '203490',
'Bobby Portis': '1626171',
'Kristaps Porzingis': '204001',
'Micah Potter': '1630695',
'Dwight Powell': '203939',
'Norman Powell': '1626181',
'Joshua Primo': '1630563',
'Taurean Prince': '1627752',
'Payton Pritchard': '1630202',
'Olivier-Maxence Prosper': '1641765',
'Trevelin Queen': '1630243',
'Neemias Queta': '1629674',
'Immanuel Quickley': '1630193',
'Lester Quinones': '1631311',
'Julius Randle': '203944',
'Duop Reath': '1641871',
'Austin Reaves': '1630559',
'Cam Reddish': '1629629',
'Paul Reed': '1630194',
'Naz Reid': '1629675',
'Jared Rhoden': '1631197',
"Sir'Jabari Rice": '1641811',
'Nick Richards': '1630208',
'Josh Richardson': '1626196',
'Duncan Robinson': '1629130',
'Jerome Robinson': '1629010',
'Mitchell Robinson': '1629011',
'Orlando Robinson': '1631115',
'Jeremiah Robinson-Earl': '1630526',
'David Roddy': '1631223',
'Ryan Rollins': '1631157',
'Derrick Rose': '201565',
'Terry Rozier': '1626179',
'Ricky Rubio': '201937',
'Rayan Rupert': '1641712',
"D'Angelo Russell": '1626156',
'Matt Ryan': '1630346',
'Domantas Sabonis': '1627734',
'Luka Samanic': '1629677',
'Jermaine Samuels Jr.': '1631257',
'Adama Sanogo': '1641766',
'Gui Santos': '1630611',
'Dario Saric': '203967',
'Olivier Sarr': '1630846',
'Marcus Sasser': '1631204',
'Admiral Schofield': '1629678',
'Dennis Schroder': '203471',
'Dereon Seabron': '1631220',
'Alperen Sengun': '1630578',
'Brice Sensabaugh': '1641729',
'Collin Sexton': '1629012',
'Landry Shamet': '1629013',
"Day'Ron Sharpe": '1630549',
'Shaedon Sharpe': '1631101',
'Ben Sheppard': '1641767',
'Pascal Siakam': '1627783',
'Ben Simmons': '1627732',
'Anfernee Simons': '1629014',
'Jericho Sims': '1630579',
'Jalen Slawson': '1641771',
'Javonte Smart': '1630606',
'Marcus Smart': '203935',
'Dru Smith': '1630696',
'Ish Smith': '202397',
'Jalen Smith': '1630188',
'Terquavion Smith': '1631173',
'Dennis Smith Jr.': '1628372',
'Jabari Smith Jr.': '1631095',
'Nick Smith Jr.': '1641733',
'Jeremy Sochan': '1631110',
'Jaden Springer': '1630531',
'Lamar Stevens': '1630205',
'Isaiah Stewart': '1630191',
'Julian Strawther': '1631124',
'Max Strus': '1629622',
'Jalen Suggs': '1630591',
'Cole Swider': '1631306',
"Jae'Sean Tate": '1630256',
'Jayson Tatum': '1628369',
'Terry Taylor': '1630678',
'Garrett Temple': '202066',
'Dalen Terry': '1631207',
'Daniel Theis': '1628464',
'Cam Thomas': '1630560',
'Amen Thompson': '1641708',
'Ausar Thompson': '1641709',
'Klay Thompson': '202691',
'Tristan Thompson': '202684',
'JT Thor': '1630550',
'Matisse Thybulle': '1629680',
'Xavier Tillman': '1630214',
'Jacob Toppin': '1631210',
'Obi Toppin': '1630167',
'Karl-Anthony Towns': '1626157',
'Gary Trent Jr.': '1629018',
'Oscar Tshiebwe': '1631131',
'P.J. Tucker': '200782',
'Myles Turner': '1626167',
'Hunter Tyson': '1641816',
'Stanley Umude': '1630649',
'Jonas Valanciunas': '202685',
'Fred VanVleet': '1627832',
'Jarred Vanderbilt': '1629020',
'Devin Vassell': '1630170',
'Sasha Vezenkov': '1628426',
'Gabe Vincent': '1629216',
'Nikola Vucevic': '202696',
'Dean Wade': '1629731',
'Franz Wagner': '1630532',
'Moritz Wagner': '1629021',
'Ish Wainright': '1630688',
'Jabari Walker': '1631133',
'Jarace Walker': '1641716',
'Lonnie Walker IV': '1629022',
'Cason Wallace': '1641717',
'Jordan Walsh': '1641775',
'P.J. Washington': '1629023',
'Duane Washington Jr.': '1630613',
'TyTy Washington Jr.': '1631102',
'Yuta Watanabe': '1629139',
'Lindy Waters III': '1630322',
'Trendon Watford': '1630570',
'Peyton Watson': '1631212',
'Victor Wembanyama': '1641705',
'Blake Wesley': '1631104',
'Russell Westbrook': '201566',
'Coby White': '1629632',
'Derrick White': '1628401',
'Dariq Whitehead': '1641727',
'Cam Whitmore': '1641715',
'Aaron Wiggins': '1630598',
'Andrew Wiggins': '203952',
'Lindell Wigginton': '1629623',
'Grant Williams': '1629684',
'Jalen Williams': '1631114',
'Jaylin Williams': '1631119',
'Kenrich Williams': '1629026',
'Mark Williams': '1631109',
'Nate Williams': '1631466',
'Patrick Williams': '1630172',
'Ziaire Williams': '1630533',
'Robert Williams III': '1629057',
'Vince Williams Jr.': '1631246',
'Zion Williamson': '1629627',
'Jalen Wilson': '1630592',
'Dylan Windler': '1629685',
'James Wiseman': '1630164',
'Isaiah Wong': '1631209',
'Christian Wood': '1626174',
'Delon Wright': '1626153',
'Thaddeus Young': '201152',
'Trae Young': '1629027',
'Omer Yurtseven': '1630209',
'Cody Zeller': '203469',
'Ivica Zubac': '1627826'}

export const TEAM_TO_TEAM_ABBR = {'Atlanta Hawks': 'ATL',
'Boston Celtics': 'BOS',
'Cleveland Cavaliers': 'CLE',
'New Orleans Pelicans': 'NOP',
'Chicago Bulls': 'CHI',
'Dallas Mavericks': 'DAL',
'Denver Nuggets': 'DEN',
'Golden State Warriors': 'GSW',
'Houston Rockets': 'HOU',
'Los Angeles Clippers': 'LAC',
'Los Angeles Lakers': 'LAL',
'Miami Heat': 'MIA',
'Milwaukee Bucks': 'MIL',
'Minnesota Timberwolves': 'MIN',
'Brooklyn Nets': 'BKN',
'New York Knicks': 'NYK',
'Orlando Magic': 'ORL',
'Indiana Pacers': 'IND',
'Philadelphia 76ers': 'PHI',
'Phoenix Suns': 'PHX',
'Portland Trail Blazers': 'POR',
'Sacramento Kings': 'SAC',
'San Antonio Spurs': 'SAS',
'Oklahoma City Thunder': 'OKC',
'Toronto Raptors': 'TOR',
'Utah Jazz': 'UTA',
'Memphis Grizzlies': 'MEM',
'Washington Wizards': 'WAS',
'Detroit Pistons': 'DET',
'Charlotte Hornets': 'CHA'}
export const TEAM_TO_TEAM_HEX_COLOR = {'Atlanta Hawks': '#C8102E',
'Boston Celtics': '#007A33',
'Cleveland Cavaliers': '#860038',
'Chicago Bulls': '#CE1141',
'Dallas Mavericks': '#00538C',
'Denver Nuggets': '#0E2240',
'Golden State Warriors': '#1D428A',
'Houston Rockets': '#CE1141',
'Los Angeles Clippers': '#c8102E',
'Los Angeles Lakers': '#552583',
'Miami Heat': '#98002E',
'Milwaukee Bucks': '#00471B',
'Minnesota Timberwolves': '#0C2340',
'Brooklyn Nets': '#000000',
'New York Knicks': '#006BB6',
'Orlando Magic': '#0077c0',
'Indiana Pacers': '#002D62',
'Philadelphia 76ers': '#006bb6',
'Phoenix Suns': '#1d1160',
'Portland Trail Blazers': '#E03A3E',
'Sacramento Kings': '#5a2d81',
'San Antonio Spurs': '#c4ced4',
'Oklahoma City Thunder': '#007ac1',
'Toronto Raptors': '#ce1141',
'Utah Jazz': '#002B5C',
'Memphis Grizzlies': '#5D76A9',
'Washington Wizards': '#002B5C',
'Detroit Pistons': '#C8102E',
'Charlotte Hornets': '#1d1160',
'New Orleans Pelicans': '#0C2340'}